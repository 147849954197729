.movement{
    display: inline-block;
    opacity: 0;
    animation-name: animation;
    animation-duration: 3s;
    animation-fill-mode: forward;
    animation-iteration-count: infinite;
}

@keyframes animation {
    from {
        opacity: 0;
        transform: translateY(-30%);
    }
    25% {
        opacity: 1;
        transform: translateX(0%);
    }
    75% {
        opacity: 1;
        transform: translateX(0%);
    }
    to {
        opacity: 0;
        transform: translateY(-20%);
    }
}

